import CarTableForCars from "./carTableForCars";
import { graphql } from "gatsby";

export default CarTableForCars;
export const query = graphql`
  query CarsByYear($skip: Int!, $limit: Int!, $carType: String!, $year: Date) {
    allCarData(
      sort: { fields: CoalescedProbDeath }
      filter: { CarTypeTeoalida: { eq: $carType }, ModelYear: { eq: $year } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedProbInjury
        ProbDeathNOTEquippedWithAEB
        ProbDeathIfEquippedWithAEB
        VehicleSizeCategory
        CoalescedRscRating
      }
    }

    groupings: allCarData(
      filter: { CarTypeTeoalida: { eq: $carType }, ModelYear: { eq: $year } }
    ) {
      group(field: CoalescedProbDeath) {
        fieldValue
        totalCount
      }
    }
  }
`;
